import { matchPath } from 'react-router-dom';
import routes from './routes';
const buildAbsolutePath = (routeName, routesConfig) => {
    for (let i = 0; i < routesConfig.length; i += 1) {
        const route = routesConfig[i];
        if (route.name === routeName) {
            return route.path;
        }
        if (route.children) {
            const nestedPath = buildAbsolutePath(routeName, route.children);
            if (nestedPath) {
                if (!route.path) {
                    return nestedPath;
                }
                // NOTE:
                // if currentPath ends with slash or nestedPath starts with slash
                // we don't need to add a slash in between
                if (route.path[route.path.length - 1] === '/' ||
                    nestedPath[0] === '/') {
                    return route.path + nestedPath;
                }
                return `${route.path}/${nestedPath}`;
            }
        }
    }
    return undefined;
};
const getAbsolutePathInternal = (routeName, routesConfig, params = {}) => {
    let path = buildAbsolutePath(routeName, routesConfig);
    if (path) {
        Object.keys(params).forEach(key => {
            path = path === null || path === void 0 ? void 0 : path.replace(`:${key}`, params[key]);
        });
    }
    return path;
};
export const getAbsolutePath = (routeName, params = {}) => getAbsolutePathInternal(routeName, routes, params);
export const matchPathPartial = (routeName, pathname) => matchPath({ path: String(getAbsolutePath(routeName)), end: false }, pathname);
